import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import PartialPageImgHeader from "../components/PartialPageImgHeader";
import TitleXL from "../components/TitleXL";
import InhaltsText from "../components/InhaltsText";
import Wrapper from "../components/Wrapper";
import HorizontalWrapper from "../components/HorizontalWrapper";
import { Helmet } from "react-helmet";

interface StellenQueryProps {
  cms: {
    entry: {
      title: string;
      uri: string;
      mainImage: [{ url: string }];
      inhaltstext: string;
    };
  };
}

const Stellen = () => {
  const data: StellenQueryProps = useStaticQuery(graphql`
    query {
      cms {
        entry(type: "stellen") {
          title
          uri
          ... on CMS_inhalt_stellen_Entry {
            mainImage {
              url
            }
            inhaltstext
          }
        }
      }
    }
  `);
  const entry = data.cms.entry;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.mainImage[0].url} />
      <HorizontalWrapper>
        <TitleXL spacingTop>{entry.title}</TitleXL>
      </HorizontalWrapper>
      <Wrapper>
        <InhaltsText html={entry.inhaltstext} />
      </Wrapper>
      {/* Loop over stellen? */}
    </DefaultLayout>
  );
};

export default Stellen;
