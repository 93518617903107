import React, { useState, useEffect } from "react";

const ImgixImg = ({ src, imgixParams }) => {
  const [newSrc, setNewSrc] = useState("");
  const [newSrcSet, setNewSrcSet] = useState("");

  useEffect(() => {
    const newNewSrc = `${src}?auto=format,compress${
      imgixParams.q ? "&q=" + imgixParams.q : "&q=20"
    }${imgixParams.fit ? "&fit=" + imgixParams.fit : ""}${
      imgixParams.crop ? "&crop=" + imgixParams.crop : ""
    }${imgixParams.w ? "&w=" + imgixParams.w : ""}${
      imgixParams.h ? "&h=" + imgixParams.h : ""
    }`;

    const srcSet = newNewSrc + "&dpr=1 1x, " + newNewSrc + "&dpr=2 2x";
    setNewSrc(newNewSrc);
    setNewSrcSet(srcSet);
  }, []);

  return <img src={newSrc} srcSet={newSrcSet} />;
};

export default ImgixImg;
